const properties = {
	width: 1,
	height: 1,
}

export default [{
	position: {
		x: 0,
		y: 61,
	},
	shape: [[1]],
	...properties,
}, {
	position: {
		x: 2,
		y: 61,
	},
	shape: [[2]],
	...properties,
}, {
	position: {
		x: 4,
		y: 61,
	},
	shape: [[3]],
	...properties,
}, {
	position: {
		x: 6,
		y: 61,
	},
	shape: [[4]],
	...properties,
}]