import empty from './empty'

const level0 = empty


export default {
	level: [
		level0,
		level0,
		level0,
		level0
	]
}