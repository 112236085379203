import type { Maybe, Function1 } from 'util/types'
import type { ColonyEntity } from 'entity/colony/types'
import { Show, For } from 'solid-js'

import $ from 'signal-chain-solid'
import Record from 'util/record'

import Storage from 'entity/storage'
import Tile from 'entity/tile'
import Unit from 'entity/unit'
import Colony from 'entity/colony'

import Commander from 'command/commander'
import Found from 'command/found'
import Road from 'command/road'
import Plow from 'command/plow'
import CutForest from 'command/cutForest'
import TradeRoute from 'command/tradeRoute'
import GoTo from 'command/goTo'

import Foreground from 'render/foreground'

import UnitMapView from 'view/map/unit'
import MapView from 'view/map'

import Dialog from 'view/ui/dialog'
import StorageGoods from 'ui/components/StorageGoods'
import GameIcon from 'ui/components/GameIcon'

import styles from './Unit.module.scss'

import type { UnitEntity } from 'entity/unit/types'


const handleGoTo = (unit: UnitEntity) => {
    const colonies = Record.getAll('colony')
        .filter(colony => Colony.isReachable(colony, unit))
        .map(colony => ({
            ...colony,
            size: colony.colonists.length,
            action: () => {
                Commander.scheduleInstead(unit.commander, GoTo.create({ unit, colony }))
            }
        }))

    if (['sea', 'water'].includes(unit.properties.travelType)) {
        Dialog.open('unit.goto.sea', {
            colonies,
            homeport: {
                name: 'London',
                action: () => {
                    Commander.scheduleInstead(unit.commander, GoTo.create({ unit, europe: true }))
                }
            }
        })
    } else if (unit.properties.travelType === 'coast') {
        Dialog.open('unit.goto.coast', {
            colonies
        })
    } else {
        Dialog.open('unit.goto.land', {
            colonies
        })
    }
}


const foundColony = (unit: UnitEntity) =>
    Commander.scheduleInstead(unit.commander, Found.create({ unit }))
const assignTransport = (unit: UnitEntity) =>
    Commander.scheduleInstead(unit.commander, TradeRoute.create({ unit }))
const buildRoad = (unit: UnitEntity) =>
    Commander.scheduleInstead(unit.commander, Road.create({ unit }))
const cutForest = (unit: UnitEntity) =>
    Commander.scheduleInstead(unit.commander, CutForest.create({ unit }))
const plow = (unit: UnitEntity) =>
    Commander.scheduleInstead(unit.commander, Plow.create({ unit }))
const goTo = (unit: UnitEntity) => handleGoTo(unit)
const cancel = (unit: UnitEntity) => Commander.clearSchedule(unit.commander)

type UnitView = {
    unit?: UnitEntity
}

function UnitComponent() {
    const unitChain = $.chain(
        $.select<void>(),
        UnitMapView.listen.selectedView,
        $.select((view: UnitView) => view?.unit)
    )
    const unit = $.solid.create(unitChain)
    const name = $.solid.create(unitChain, $.maybe.chain(Unit.chain.name))

    const cargo = $.solid.create(
        unitChain,
        $.select(unit => unit?.storage),
        Storage.signal
    )
    const equipment = $.solid.create(
        unitChain,
        $.select(unit => unit?.equipment),
        Storage.signal
    )

    const command = $.solid.create(
        unitChain,
        $.maybe.listen.key('command')
    )

    const passengers = $.solid.create(
        unitChain,
        $.maybe.listen.key('passengers')
    )

    const propertyChain = $.chain(
        unitChain,
        $.maybe.listen.key('properties'),
    )
    const properties = $.solid.create(propertyChain)
    const cost = $.solid.create(
        propertyChain,
        $.maybe.listen.key('cost'),
        $.select(cost => cost?.toFixed(0) ?? '')
    )

    const speedChain = $.chain(
        $.select<UnitEntity>(),
        $.combine(
            $.select(),
            $.listen.key('properties'),
            $.chain(
                $.select(unit => unit.equipment),
                Storage.signal,
            )
        ),
        $.select(([unit]) => Unit.speed(unit) as number)
    )
    const speed = $.solid.create(
        unitChain,
        $.maybe.chain(speedChain),
        $.select(speed => speed?.toFixed(2) ?? '')
    )

    const strength = $.solid.create(
        unitChain,
        $.type.not.isNothing(
            $.combine(
                $.select(),
                $.listen.key('mapCoordinates'),
                $.chain(
                    $.select(unit => unit?.equipment),
                    Storage.signal
                )
            ),
            $.select(([unit]) => unit && Unit.strength(unit).toFixed(2) as string)
        )
    )

    const tile = $.solid.create(
        unitChain,
        $.maybe.listen.key('tile')
    )

    const coords = $.solid.create(
        unitChain,
        $.maybe.listen.key('mapCoordinates')
    )

    const supplyColonyChain = $.chain(
        unitChain,
        $.maybe.listen.key('mapCoordinates'),
        $.select(coords => coords && Tile.supportingColony(Tile.closest(coords)) as Maybe<ColonyEntity>)
    )
    const supplyColonyName = $.solid.create(
        supplyColonyChain,
        $.maybe.select(colony => colony.name)
    )

    const canSupply = $.solid.create(
        $.combine(
            unitChain,
            supplyColonyChain
        ),
        $.select(([unit, colony]) => {
            if (!unit || !colony) {
                return false
            }

            if (unit.properties.repair) {
                return Object.entries(unit.properties.repair)
                    .every(
                        ([name, level]) => {
                            return !!colony.newBuildings.find(
                                building => building.name === name && building.level >= level
                            )
                        }
                    )
            }

            return true
        }),
        $.select(x => !!x)
    )

    const treasure = $.solid.create(
        unitChain,
        $.select(unit => unit?.treasure)
    )

    const screen = $.solid.create(Foreground.listen.screen)
    const isVisible = () => !screen() && !!unit()

    const supplyFragment = () => canSupply()
        ? <>Supplies from <b>{supplyColonyName()}</b></>
        : <>No external supplies</>

    const center = () => { if (coords()) { MapView.centerAt(coords()!, 350) } }

    const commandChain = $.chain(
        unitChain,
        $.maybe.listen.key('command')
    )
    const isPioneering = $.solid.create(
        commandChain,
        $.maybe.select(command => ['cutForest', 'plow', 'road'].includes(command.id)),
        $.select(x => !!x)
    )
    const isTrading = $.solid.create(
        commandChain,
        $.maybe.select(command => command.id === 'tradeRoute'),
        $.select(x => !!x)
    )

    const isMoving = () => !tile()

    const canFoundColony = () =>
        properties()?.canFound &&
        !isMoving() &&
        !Tile.radius(tile()).some(tile => tile.colony) &&
        !tile()?.settlement &&
        !isPioneering()
    const canGoto = () => !isPioneering()
    const canAssignTransport = () =>
        properties()?.cargo! > 0 && passengers()?.length === 0 && !isPioneering() && !isTrading()
    const canPioneer = () =>
        properties()?.canTerraform &&
        !isMoving() &&
        !isPioneering()
        !tile()?.settlement
    const canBuildRoad = () =>
        canPioneer() &&
        !tile()?.road
    const canPlow = () =>
        canPioneer() &&
        !tile()?.forest &&
        !tile()?.plowed
    const canCutForest = () =>
        canPioneer() &&
        tile()?.forest

    const cancelCommandName = () => ({
        cutForest: 'Cancel Cutting Forest',
        plow: 'Cancel Plow',
        tradeRoute: 'Cancel Automatic Transport',
        road: 'Cancel Building Road',
    })[command()?.id ?? '']


    const commands = () => [
        canFoundColony() && ['Found Colony', foundColony],
        canGoto() && ['Go to', goTo],
        canAssignTransport() && ['Assign Automatic Transport', assignTransport],
        canPlow() && ['Build Farm', plow],
        canBuildRoad() && ['Build Road', buildRoad],
        canCutForest() && ['Cut Forest', cutForest],
        cancelCommandName() && [cancelCommandName(), cancel],
    ].filter(x => !!x) as [string, Function1<UnitEntity>][]


    return (
        <Show when={isVisible()}>
            <div class={styles.main}>
                <div class={styles.commands}>
                    <For each={commands()}>
                        {([text, action]) => <div onClick={() => unit() && action(unit()!)}>{text}</div>}
                    </For>
                </div>
                <div onClick={center} class={styles.name}>{name()}</div>
                <div class={styles.command}><i>{command()?.display}</i></div>
                <div class={styles.properties}>
                    <span><GameIcon icon="go" scale="0.75" />{speed()}</span>
                    <span><GameIcon icon="combat" scale="0.75" />{strength()}</span>
                    <Show when={cost()}>
                        <span><GameIcon icon="gold" scale="0.75" />{cost()}</span>
                    </Show>
                </div>
                <Show when={treasure()}>
                    <div class={styles.treasure}>
                        <b>{treasure()}</b><GameIcon icon="gold" />
                    </div>
                </Show>
                <div class={styles.cargo}>
                    <StorageGoods goods={cargo()} />
                </div>
                <Show when={passengers()?.length! > 0}>
                    <div class={styles.passengers}>
                        <For each={passengers()}>
                            {passenger => <div class={styles.passenger}><GameIcon unit={passenger} scale={2} /></div>}
                        </For>
                    </div>
                </Show>
                <div class={styles.supply}>{supplyFragment()}</div>
                <div class={styles.equipment}><StorageGoods goods={equipment()} /></div>
            </div>
        </Show>
    )
}

export default UnitComponent
