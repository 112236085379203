import Dialog from './Dialog'
import Overlay from './overlay/Overlay'
import DetailView from './DetailView'


function Main() {
  return <>
    <Overlay />
    <DetailView />
    <Dialog />
  </>
}

export default Main


